.pill--standard {
  background-color: var(--color-primary-lighter-80);
  color: var(--color-primary);
  font-family: var(--font-primary);
  @include fontSmooth;
  svg {
    path {
      stroke: var(--color-primary);
    }
  }
}
