@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');
:root,
[data-theme="tsw"] {
    // font family
    --font-primary: 'Inter', sans-serif;
    --font-secondary: 'Work Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--font-secondary);
}