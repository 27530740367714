.tsw-c-testimoinals {
  .d8-c-card {
    transform: rotateZ(-2deg);
    margin-top: 10px;
  }
}

@include breakpoint(sm) {
  .tsw-c-testimoinals {
    .d8-c-card {
      transform: none;
    }
  }

  .tsw-c-testimoinals {
    .padding-sm:first-child {
      .d8-c-card {
        transform: rotateZ(-5deg);
        margin-top: 10px;
      }
    }
    .padding-sm:last-child {
      .d8-c-card {
        transform: rotateZ(5deg);
        margin-top: 10px;
      }
    }
  }
}
