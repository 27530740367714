.destination-austria, .destination-chamonix {
    .d8-c-hero {
        .subtitle {
            font-size: var(--text-xxl);
            font-weight: 600;
            margin-top: 0;
            font-family: var(--font-secondary);
        }
    }
    .tsw-c-overview {
        background-image: url(https://cdn.theskiweek.com/images/destinations/austria/overview.png);
        .dots-svg{
            g {
                fill: #35B1FB;
            }
        }
        .decoration-square {
            background-color: var(--color-primary);
        }
        .overview-items {
            .overview-items__item {
                .item-icon__wrapper {
                    background-color: var(--color-primary-lighter-80);
                    svg {
                        g {
                            stroke: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
    .highlights-itinerary-wrapper {
        background-image: url(https://assets.theskiweek.com/wp-content/uploads/2020/07/Austria-bg2.jpg);
    }
    .tsw-c-accomodation {
        &::after {
            background-image: url(https://cdn.theskiweek.com/images/destinations/austria/Austria-bg2.svg);
        }
        .icon__wrapper {
            background-color: var(--color-primary-lighter-80);
            svg {
                path, g {
                    stroke: var(--color-primary);
                }
            }
        }
    }
    .tsw-c-accomodation-options {
        .option-left {
            background-color: var(--color-primary);
        }
        .option-right {
            background-color: var(--color-primary-dark);;
        }
    }
}