:root,
[data-theme="tsw"] {
    --color-primary-100: #E5F6F5;
    --color-primary-200: #CCEDEC;
    --color-primary-300: #B2E5E3;
    --color-primary-400: #7FD4D1;
    --color-primary-500: #4CC2BE;
    --color-primary-600: #32BAB5;
    --color-primary-700: #00A9A3;
    --color-primary-800: #008782;
    --color-primary-900: #007672;
    // main
    --color-primary-darker: var(--color-primary-900);
    --color-primary-dark: var(--color-primary-800);
    --color-primary: var(--color-primary-700);
    --color-primary-light: var(--color-primary-600);
    --color-primary-lighter: var(--color-primary-500);
    --color-primary-lighter-80: var(--color-primary-200);
    --color-primary-lighter-90: var(--color-primary-100);

    @include defineColorHSL(--color-accent-darker, 352, 95%, 38%);
    @include defineColorHSL(--color-accent-dark, 352, 95%, 48%);
    @include defineColorHSL(--color-accent, 352, 95%, 58%);
    @include defineColorHSL(--color-accent-light, 352, 95%, 68%);
    @include defineColorHSL(--color-accent-lighter, 352, 95%, 78%);

    --color-secondary-100: #F9F9FF;
    --color-secondary-200: #ECECFF;
    --color-secondary-300: #D9D9FF;
    --color-secondary-400: #BAB9FF;
    --color-secondary-500: #9392FF;
    --color-secondary-600: #5552FF;
    --color-secondary-700: #4340FC;
    --color-secondary-800: #2D2AE1;
    --color-secondary-900: #1613B8;

    //new brand colours
    --color-brand-primary: transparent;
    --color-brand-secondary: var(--color-black);
    --color-brand-highlight: var(--color-black);
    --color-brand-highlight-2: var(--color-black);

    --color-secondary-darker: var(--color-secondary-900);
    --color-secondary-dark: var(--color-secondary-800);
    --color-secondary: var(--color-secondary-700);
    --color-secondary-light: var(--color-secondary-600);
    --color-secondary-lighter: var(--color-secondary-500);

    @include defineColorHSL(--color-tertiary, 216, 68%, 23%);

    @include defineColorHSL(--color-day8-darker, 44, 98%, 39%);
    @include defineColorHSL(--color-day8-dark, 44, 98%, 49%);
    @include defineColorHSL(--color-day8, 44, 98%, 59%);
    @include defineColorHSL(--color-day8-light, 44, 98%, 69%);
    @include defineColorHSL(--color-day8-lighter, 44, 98%, 79%);

    @include defineColorHSL(--color-black, 0, 0%, 20%);
    @include defineColorHSL(--color-white, 0, 0%, 100%);

    // feedback
    --color-warning-100: #FFFCF5;
    --color-warning-200: #FFF6E4;
    --color-warning-300: #FFECC7;
    --color-warning-400: #FFDC99;
    --color-warning-500: #FFCA65;
    --color-warning-600: #FFBB38;
    --color-warning-700: #FFAD0D;
    --color-warning-800: #FF8F00;
    --color-warning-900: #F07300;

    --color-warning-darker: var(--color-warning-900);
    --color-warning-dark: var(--color-warning-800);
    --color-warning: var(--color-warning-700);
    --color-warning-light: var(--color-warning-600);
    --color-warning-lighter: var(--color-warning-500);

    --color-success-100: #ECFBEE;
    --color-success-200: #D9F7DE;
    --color-success-300: #B4F0BE;
    --color-success-400: #8EE99E;
    --color-success-500: #7CE58E;
    --color-success-600: #69E27E;
    --color-success-700: #44DB5E;
    --color-success-800: #3DC554;
    --color-success-900: #2F9941;

    --color-success-darker: var(--color-success-900);
    --color-success-dark: var(--color-success-800);
    --color-success: var(--color-success-700);
    --color-success-light: var(--color-success-600);
    --color-success-lighter: var(--color-success-500);

    --color-error-100: #FFF8F8;
    --color-error-200: #FFEAEA;
    --color-error-300: #FFC2C2;
    --color-error-400: #FF8D8D;
    --color-error-500: #FF5D5D;
    --color-error-600: #F03D3D;
    --color-error-700: #DD2727;
    --color-error-800: #BD0303;
    --color-error-900: #A50000;

    --color-error-darker: var(--color-error-900);
    --color-error-dark: var(--color-error-800);
    --color-error: var(--color-error-700);
    --color-error-light: var(--color-error-600);
    --color-error-lighter: var(--color-error-500);

    // color contrasts

    // TODO - this is bigger job as expected

    // --color-contrast-100: #FAFBFB;
    // --color-contrast-200: #F7F8F9;
    // --color-contrast-300: #F1F2F4;
    // --color-contrast-400: #E1E4E8;
    // --color-contrast-500: #C9CED6;
    // --color-contrast-600: #B0B7C3;
    // --color-contrast-700: #A7AEBB;
    // --color-contrast-800: #98A1B1;
    // --color-contrast-900: #8A94A6;

    // --color-bg: var(--color-contrast-100);
    // --color-contrast-lower: var(--color-contrast-200);
    // // --color-contrast-low: var(--color-contrast-400);
    // --color-contrast-medium: var(--color-contrast-600);
    // --color-contrast-high: var(--color-contrast-700);
    // --color-contrast-higher: var(--color-contrast-900);


    @include defineColorHSL(--color-bg, 160, 14%, 96%);
    @include defineColorHSL(--color-contrast-lower, 0, 0%, 96%);
    @include defineColorHSL(--color-contrast-low, 218, 22%, 90%); // light grey
    @include defineColorHSL(--color-contrast-medium, 221, 12%, 73%); // mid grey
    @include defineColorHSL(--color-contrast-high, 220, 8%, 55%); // dark grey
    @include defineColorHSL(--color-contrast-higher, 0, 0%, 20%);

    // ratings
    @include defineColorHSL(--color-standard, 161, 76%, 58%);
    @include defineColorHSL(--color-premium, 351, 100%, 71%);
    @include defineColorHSL(--color-premium-plus, 260, 96%, 56%);
    @include defineColorHSL(--color-basic, 89, 73%, 48%);
    @include defineColorHSL(--color-catered, 260, 96%, 56%);

    // misc
    @include defineColorHSL(--color-super-dark-blue, 216, 68%, 23%);
    @include defineColorHSL(--color-neon-green, 144, 95%, 56%);
    @include defineColorHSL(--color-soft-blue, 194, 95%, 51%);
    @include defineColorHSL(--color-orange, 21, 100%, 65%);
}
