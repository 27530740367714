.destination-japan {
    .d8-c-hero {
        .subtitle {
            font-size: var(--text-xxl);
            font-weight: 600;
            margin-top: 0;
            font-family: var(--font-secondary);
        }
    }
    .tsw-c-intro {
        background-image: url(https://cdn.theskiweek.com/images/destinations/japan/texture-bg.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 0;
        padding-bottom: var(--space-xxl);
        .d8-c-card {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 40%;
                right: 90%;
                transform: translateY(-50%);
                background-image: url(https://cdn.theskiweek.com/images/destinations/japan/intro-left.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 250px;
                height: 60px;
            }
            &::after {
                display: none;
                content: '';
                position: absolute;
                top: 60%;
                left: 90%;
                transform: translateY(-50%);
                background-image: url(https://cdn.theskiweek.com/images/destinations/japan/intro-right.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 250px;
                height: 60px;
                @media only screen and (min-width: 1120px){
                    display: block;
                }
            }
        }
        .intro-text {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                background-image: url(https://cdn.theskiweek.com/images/destinations/japan/intro-bottom.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 250px;
                height: 60px;
            }
        }
    }
    #destination-gallery {
        .destination-gallery-wrapper{
            background-image: url(https://cdn.theskiweek.com/images/destinations/japan/texture-bg.jpg);
            margin-top: 0;
            margin-bottom: 0;
            padding-top: var(--space-xxl);
            padding-bottom: var(--space-xxl);
        }
    }
    .tsw-c-overview {
        position: relative;
        background-size: unset;
        background-position: unset;
        @media only screen and (min-width: 1400px){
            // this breaks production build
            // background: url(https://cdn.theskiweek.com/images/destinations/japan/japan-fan-bg.svg) bottom right no-repeat,  url(https://cdn.theskiweek.com/images/destinations/japan/fans1.svg) top left no-repeat;
        }
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-image: url(https://cdn.theskiweek.com/images/destinations/japan/overview-bottom.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 120px;
            height: 100px;
        }
        .dots-svg{
            g {
                fill: #D8D8D8;
            }
        }
        .decoration-square {
            background-color: #D62E2D;
        }
        .overview-items {
            .overview-items__item {
                .item-icon__wrapper {
                    background-color: var(--color-white);
                    svg {
                        g, path {
                            stroke: #D62E2D;
                            fill: #D62E2D;
                        }
                    }
                }
            }
        }
    }
    .highlights-itinerary-wrapper {
        background-image: url(https://assets.theskiweek.com/wp-content/uploads/2020/07/Japan-bg2.jpg);
        .highlights-wrapper {
            &::before {
                content: '';
                position: absolute;
                top: 10%;
                left: 10%;
                background-image: url(https://cdn.theskiweek.com/images/destinations/japan/highlights-left.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 240px;
                height: 100px;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 10%;
                background-image: url(https://cdn.theskiweek.com/images/destinations/japan/highlights-right.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 240px;
                height: 100px;
            }
        }
        .itinerary-wrapper {
            &::before {
                content: '';
                position: absolute;
                top: 10%;
                left: 10%;
                background-image: url(https://cdn.theskiweek.com/images/destinations/japan/itinerary-left.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 240px;
                height: 100px;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 10%;
                background-image: url(https://cdn.theskiweek.com/images/destinations/japan/itinerary-right.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 240px;
                height: 100px;
            }
        }
    }
    .tsw-c-accomodation {
        &::after {
            // this breaks production build
            // background: url(https://cdn.theskiweek.com/images/destinations/japan/Rectangle-japan.svg) bottom right no-repeat;
        }
        .icon__wrapper {
            background-color: var(--color-white);
            svg {
                path, g {
                    stroke: #D62E2D;
                }
            }
        }
    }
    .tsw-c-accomodation-options {
        .option-left {
            background-color: var(--color-primary);
        }
        .option-right {
            background-color: #D62E2D;
        }
    }
}