.d8-c-destination-guide-grid {
    @include breakpoint(sm) {
        @include grid;
        @include gridAdvanced((5, 12, 4, 11),  (2, 11, 1, 10), (1, 5, 4, 12));
    }

    .d8-c-card {
      min-width: auto;
    }
}


.d8-c-destination-decorator {
  position: absolute;
  left: -24px;
  top: -24px;
  z-index: 2;
}
