.dark-mode {
    // Taken from Ultra styles, but removed primary / secondary color var changes etc
    --color-black: hsl(240, 24%, 96%);
    --color-white: hsl(0, 0%, 15%);
    // color contrasts
    --color-bg: hsl(0, 0%, 14%);
    --color-contrast-lower: hsl(240, 8%, 12%);
    --color-contrast-low: hsl(240, 4%, 20%);
    --color-contrast-medium: hsl(240, 1%, 48%);
    --color-contrast-high: hsl(240, 1%, 83%);
    --color-contrast-higher: hsl(0, 0%, 95%);

    h1,
    h2,
    h3,
    h4,
    h5,
    .d8-u-text-bold {
        font-weight: 700;
    }
    .d8-u-color-white {
        color: var(--color-black);
    }

    .d8-c-navbar {
        background: var(--color-contrast-low);
        .logo {
            svg {
                path,
                g {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
    }
    .d8-c-sticky-navbar {
        background: var(--color-contrast-low);
    }
    .d8-c-phone-number {
        svg {
            path,
            g {
                fill: var(--color-black);
                stroke: var(--color-black);
            }
        }
        p {
            color: var(--color-black);
        }
    }
    .d8-u-contrast-lower-bg {
        background-color: #393939;
    }
    .d8-c-card {
        background-color: #393939;
        .d8-u-color-white {
            color: var(--color-black);
        }
    }
    .d8-c-card-slider {
        .card-slider-controls {
            &.right {
                background: linear-gradient(-90deg, rgba(38, 38, 38, 1), rgba(38, 38, 38, 0));
            }
            &.left {
                left: 0;
                background: linear-gradient(-90deg, rgba(38, 38, 38, 0), rgba(38, 38, 38, 1));
            }
        }
    }
    .d8-c-input {
        .form-control{
            background-color: var(--color-black);
            color: var(--color-contrast-medium);
        }
    }
    .d8-c-input .react-tel-input input, .d8-c-select select  {
        background-color: var(--color-black)!important;
        color: var(--color-contrast-medium);
    }
    .tyw-c-intro {
        svg {
            path,
            g {
                stroke: var(--color-primary);
            }
        }
    }
    .d8-c-destination-map {
        .d8-c-card-slider {
            .card-slider-controls {
                &.right {
                    background: none;
                }
                &.left {
                    background: none;
                }
            }
        }
        .map-card{
            .d8-svg-location-bold{
                path,
                g {
                    fill: var(--color-primary);
                }
            }
        }
    }
    .d8-c-weekly-tabs {
        .weekly-tabs-weeks {
            .overflow__wrapper {
                .weeks__wrapper {
                    .weeks__week {
                        .week-top {
                            background: var(--color-black);
                            color: var(--color-white);
                        }
                        .week-bottom {
                            color: var(--color-black);
                        }
                        &.active {
                            .week-top {
                                background: var(--color-primary);
                                color: var(--color-black);
                            }
                        }
                    }
                }
            }
        }
        .d8-c-card {
            background-color: var(--color-black);
            h4 {
                color: var(--color-white);
            }
        }
    }
    .d8-c-footer {
        .logo {
            svg {
                g {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
        .footer--content {
            .btn--ghost {
                background-color: var(--color-black);
                color: var(--color-white);
            }
        }
        .partners-links {
            .d8-svg-qd-logo,
            .d8-svg-tsw-logo,
            .d8-svg-ohso-logo {
                g,
                path {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
            .d8-svg-abta-logo {
                g,
                path,
                polygon {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
            .d8-svg-ynf-logo {
                g,
                path {
                    // fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
    }
    .cookie-banner {
        background: var(--color-primary);
    }

    .line-split {
      width:100%;
      text-align:center;
      border-bottom: 1px solid var(--color-contrast-low);
      line-height:0.1em;
      span {
        background: var(--color-white);
      }
    }

    .btn--primary, .btn--secondary  {
      color: var(--color-black);
      // zindex fix? some reason some buttons have weird indexes
      z-index: 1;
    }

    .country-list {
      color: var(--color-contrast-medium);
    }

}
