.tsw-c-accomodation {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
}