.tsw-c-what-is-tsw {
  position: relative;
    @include breakpoint(sm) {
      &::after {
      position: absolute;
      top: 35%;
      right: 35%;
      content: '';
      width: 300px;
      height: 300px;
      background-image: url(https://cdn.theyachtweek.com/tsw/2020/home-deco-1.png);
      background-size: cover;
      }
    }

    .home-gallery {
      position: relative;
    }

    .home-gallery:after {
      position: absolute;
      top: 20%;
      right: 35%;
      content: '';
      width: 200px;
      height: 200px;
      background-size: cover;
      z-index: -1;
    }

    .home-gallery-1:after {
        background-image: url(https://cdn.theyachtweek.com/tsw/2020/home-deco-1.png);
    }
    .home-gallery-2:after {
        background-image: url(https://cdn.theyachtweek.com/tsw/2020/home-deco-2.png);
    }
    .home-gallery-3:after {
        background-image: url(https://cdn.theyachtweek.com/tsw/2020/home-deco-3.png);
    }
    .home-gallery-4:after {
        background-image: url(https://cdn.theyachtweek.com/tsw/2020/home-deco-4.png);
    }
    &.chamonix {
      .home-gallery:after{
        display: none;
      }
      &::after {
        display: none;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 570px;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(https://cdn.theskiweek.com/images/destinations/chamonix/dolce-bg.svg);
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
}

.d8-c-sticky-banner-contain {
  @media (max-height: 767px) {
    position: relative;
  }
}