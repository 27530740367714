.d8-c-how-it-works-grid{
    @include grid;
    @include gridAdvanced((4, -1, 1, 9), (7, -1, 3, 9), (2, 12, 2, 8), (1, 6, 3, 9) );
    // default color for square
    .decoration-square{
        background-color: #3464b6;
    }
}


.d8-c-installments-example  {
  max-width: 320px;
  .d8-u-component-padding {
    border-bottom: 1px solid var(--color-contrast-lower);
  }

  .d8-c-installments-example-svg-1 {
    max-width: 50%;
    position: absolute;
    bottom: -20px;
    left: -30px;
  }

  .d8-c-installments-example-svg-2 {
    position: absolute;
    right: -80px;
    max-width: 50%;
    transform: rotateZ(90deg);
    top: 80px;
  }
}
