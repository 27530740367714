.d8-c-tsw-why {
  svg {
    display: none;
  }

  @include breakpoint(sm) {
    svg {
      display: block;
    }
  }

  .yellow-triangles {
    position: absolute;
    width: 32px;
    left: -16px;
    bottom: 16px;
  }

  .green-triangles {
    position: absolute;
    height: 32px;
    right: -48px;
    bottom: 48px;
  }

  .zigzag-wrap {
    position: absolute;
    top: 48px;
    right: -24px;
    width: 128px;
    svg {
      margin-bottom: 8px;
      &:nth-child(2) {
        opacity: 0.5;
      }
      &:nth-child(3) {
        opacity: 0.2;
      }
    }
  }
}
