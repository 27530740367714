.tsw-c-crew-finder-intro{
  .d8-c-crewfinder-intro-grid {
    position: relative;
    @include breakpoint(sm) {
      max-height: 400px;
      @include grid;
      @include gridAdvanced((5, 10, 1, 6),  (1, 6, 3, 10), (8, -1, 2, 9));
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      background-color: #dbd6f3;
      border-radius: var(--radius-md);
    }
  }
}

.d8-c-crew-finder-grid {
      @include grid;
      @include gridAdvanced((6, 4, 2, 6),  (1, 8, 3, 7), (3, 11, 1, 8));
      @include breakpoint(xs) {
        @include gridAdvanced((6, -1, 2, 6),  (1, 8, 3, 7), (3, 11, 1, 8));
      }
}
