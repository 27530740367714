@import "~day8-react/build/styles/design-system/custom-style/_grid-mixins.scss";
.tsw-c-overview {
    .overview-decoration {
        @include grid;
        @include gridAdvanced((4, -1, 1, 9), (7, -1, 3, 9), (2, 12, 2, 8), (1, 6, 3, 9) );
        // default color for square
        .decoration-square{
            background-color: #2F6085;
        }
    }
}